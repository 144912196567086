// import { homeRoutes } from 'data/routes';
import { homeRoutes } from 'data/homeRoutes';
import { ProductVersion } from 'models/OrgModels';
import { RouteKeys } from 'models/RouteModels';
import React, { createContext, useContext, useState } from 'react';

const AppSettingsContext = createContext<AppSettings>({
    homeRoute: homeRoutes[RouteKeys.PATIENT_LIST],
    productVersion: ProductVersion.FULL
});

interface FeatureFlagContextProps {
    children: React.ReactNode
    appSettings: AppSettings
}

type AppSettings = {
    homeRoute: string;
    productVersion: ProductVersion
}

export const AppSettingsProvider: React.FC<FeatureFlagContextProps> = ({ children, appSettings }) => {
    const [AppSettings] = useState<AppSettings>(appSettings);

    return (
        <AppSettingsContext.Provider value={AppSettings}>
            {children}
        </AppSettingsContext.Provider>
    );
};

export const useAppSettings = (): AppSettings => {
    return useContext<AppSettings>(AppSettingsContext);
};