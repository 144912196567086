export enum Roles {
  JAI_ADMIN = 'superAdmin', // JointAI internal staff only - allows access to the jdx master dashboard
  ADMIN = 'admin',
  USER = 'user',
  VIEWER = 'viewer',
}

export const internalOnlyRoles: { [R in Roles]?: true } = {
  [Roles.JAI_ADMIN]: true,
};

export interface NewOrgInvite {
  org_id: string;
  email: string;
  role: Roles;
}

export interface OrgInvite extends NewOrgInvite {
  invite_id: string;
  created_at: string;
  updated_at: string;
}

export interface NewOrgRole {
  mp_id: string;
  org_id: string;
  role: Roles;
}

export enum ProductVersion {
  FULL = "FULL",
  TRIAGE = "TRIAGE"
}

export interface OrgRole extends NewOrgRole {
  org_role_id: string;
  created_at: string;
  updated_at: string;
  is_org_subscribed: boolean;
  product_version: ProductVersion;
}

export interface NewOrg {
  city: string;
  country: string;
  name: string;
  state: string;
  // orgs created before 3/27/24 will not have the below information,
  // hence the "undefined" option here
  phone: string | undefined;
  contact_email: string | undefined;
  point_of_contact?: string;
}

// org logo uploaded after onboarding

export interface Org extends NewOrg {
  org_id: string;
  created_at: string;
  updated_at: string;
  // orgs created before 3/27/24 will not have the below information,
  // hence the "undefined" option here
  subscribed?: boolean; // set by a jointdx admin via the jointdx dashboard once a client has paid
  logo_s3_key?: string;
  logo_url?: string;
  use_pros: boolean;
}

export interface UpdateOrgBody {
  city?: string;
  country?: string;
  name?: string;
  state?: string;
  phone?: string;
  contact_email?: string | undefined;
  point_of_contact?: string;
  use_pros?: boolean;
}
