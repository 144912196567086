import React, { useState } from 'react';
import { useBreakpoints } from 'hooks/useBreakpoints';
import MDBox from 'components/atoms/MDBox/MDBox';
import borders from 'assets/theme/base/borders';
import { Breakpoints, MUIColors, Shadows } from 'models/StyleModels';
import colors from 'assets/theme/base/colors';
import { containerStyles, formatDateOrdinals, getFormattedAppointmentDate } from 'helpers/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrgDashboardModes } from 'components/pages/OrganizationDashboardPage/OrganizationDashboardPage.types';
import { Modal } from "@mui/material"
import { faX } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import MDButton from 'components/atoms/MDButton/MDButton';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { useAppointmentMutations } from 'hooks/useAppointmentMutations';
import Spinner from 'components/molecules/Spinner/Spinner';

interface ModifyAppointmentModalProps {
  closeModal(): void;
  handleShowAlert?(): void;
  appointmentDetails: {
    appointment_id: string;
    appointment_date: string;
  };
  patientDetails: {
    first_name: string;
    last_name: string;
  };
  selectedDateISOString?: string;
  mode: OrgDashboardModes | 'quickstart';
}

enum ModalModes {
  DELETE = 'DELETE',
  DELETE_COMPLETE = 'DELETE_COMPLETE',
  MODIFY = 'MODIFY',
  DEFAULT = 'DEFAULT',
}

const ModifyAppointmentModal: React.FC<ModifyAppointmentModalProps> = ({
  closeModal,
  appointmentDetails,
  patientDetails,
  handleShowAlert,
}) => {
  const {
    deleteAppointmentMutation,
    deleteAppointmentLoading,
    deleteAppointmentError,
  } = useAppointmentMutations()

  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.SMALL });
  const [content, setContent] = useState<ModalModes>(ModalModes.DEFAULT);
  const {
    month,
    ordinalDate,
    formattedTime
  } = getFormattedAppointmentDate(appointmentDetails.appointment_date);

  const handleDeleteAppointment = async () => {
    try {
      await deleteAppointmentMutation({
        appointmentId: appointmentDetails.appointment_id,
        appointmentDate: appointmentDetails.appointment_date,
      });
      setContent(ModalModes.DELETE_COMPLETE);
    } catch (err) {
      handleShowAlert && handleShowAlert();
    }
  }

  const getContent = (mode: ModalModes) => {
    switch (mode) {
      case ModalModes.DELETE:
        return (
          <MDBox display="grid" justifyContent="center" alignItems="center" gap="2rem">
            <MDTypography color={MUIColors.SECONDARY} variant='h3' textAlign="center">Confirm Appointment Cancellation</MDTypography>

            <MDTypography>
              You are cancelling {patientDetails.first_name} {patientDetails.last_name}’s appointment on {month} {ordinalDate} at {formattedTime}. You cannot undo this action.
            </MDTypography>

            {!deleteAppointmentLoading ? (
              <MDBox display="flex" justifyContent="center" alignItems="center" gap="2rem">
                <MDButton variant='contained' color={MUIColors.SECONDARY} onClick={handleDeleteAppointment}>
                  Yes
                </MDButton>

                <MDButton variant='contained' color={MUIColors.SECONDARY} onClick={closeModal}>
                  No
                </MDButton>
              </MDBox>) : (
              <MDBox display="flex" justifyContent="center" alignItems="center" gap="2rem">
                <Spinner />
              </MDBox>
            )}

            {deleteAppointmentError && (
              <MDTypography color={MUIColors.ERROR} variant='h6' textAlign="center">
                An error occurred. Please try again.
              </MDTypography>
            )}
          </MDBox>
        )

      case ModalModes.DELETE_COMPLETE:
        return (
          <MDBox display="grid" justifyContent="center" alignItems="center" gap="2rem">
            <MDTypography color={MUIColors.SECONDARY} variant='h3' textAlign="center">Confirm Appointment Cancellation</MDTypography>

            <MDBox display="flex" justifyContent="center" alignItems="center" gap="2rem">
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={colors.success.main}
                cursor='pointer'
                size="4x"
              />
            </MDBox>

            <MDTypography>
              {patientDetails.first_name} {patientDetails.last_name}’s appointment on has been cancelled.
            </MDTypography>

            <MDBox display="flex" justifyContent="center" alignItems="center" gap="2rem">
              <MDButton variant='contained' color={MUIColors.SECONDARY} onClick={closeModal}>
                Finish
              </MDButton>
            </MDBox>
          </MDBox>
        )

      case ModalModes.DEFAULT:
      default:
        return (
          <MDBox display="grid" justifyContent="center" alignItems="center" gap="2rem">
            <MDTypography color={MUIColors.SECONDARY} variant='h4' textAlign="center">Confirm Appointment Cancellation</MDTypography>
            <MDBox display="flex" justifyContent="center" alignItems="center" gap="2rem">
              <MDBox
                display="grid"
                justifyContent="center"
                gap="1rem"
                onClick={() => setContent(ModalModes.DELETE)}
                style={{
                  backgroundColor: colors.secondary.main,
                  color: colors.white.main,
                  padding: '1rem',
                  borderRadius: borders.borderRadius.lg,
                  cursor: 'pointer',
                  width: 'fit-content',
                }}
              >
                <MDBox display="grid" justifyContent="center" alignItems="center">
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    color={colors.white.main}
                    cursor='pointer'
                    size="4x"
                  />
                </MDBox>
                <MDTypography textAlign="center" display="grid">
                  Cancel<br />Appointment
                </MDTypography>
              </MDBox>

            </MDBox >
            <MDBox display="flex" justifyContent="center" alignItems="center" gap="2rem">
              <MDButton variant='contained' color={MUIColors.SECONDARY} onClick={closeModal}>
                Cancel
              </MDButton>
            </MDBox>
          </MDBox>
        )
    }
  }

  return (
    <Modal
      {...containerStyles({
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
      open={true}
      onClose={closeModal}
    >
      <MDBox
        shadow={Shadows.SMALL}
        borderRadius={borders.borderRadius.lg}
        sx={{
          padding: '3rem',
          backgroundColor: colors.white.main,
          width: breakpointBreached ? '90%' : '50%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          maxHeight: '95vh',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <FontAwesomeIcon
          icon={faX}
          color={colors.primary.main}
          cursor='pointer'
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '1rem',
          }}
        />

        {getContent(content)}

      </MDBox>
    </Modal >
  );
};

export default ModifyAppointmentModal;
