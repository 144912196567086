import { useNavigate } from "react-router-dom";
import { routesMap } from "data/routes";
import { RouteKeys } from "models/RouteModels";

export const useNavigation = () => {

  const navigate = useNavigate();

  // a navigation helper function which pipes the given appointment
  // details along with any additional query params into the URL the user
  // is navigating to. used to navigate between steps in the exam flow,
  // as each page requires certain appointment information to be present
  // in the params in order to properly fetch the object from the database
  const navigateToBaseRouteWithAptmtDetails = ({
    routeKey,
    appointmentId,
    appointmentDateISOString,
    addtlQueryParams,
  }: {
    routeKey: RouteKeys;
    appointmentId: string;
    appointmentDateISOString: string;
    addtlQueryParams?: { [key: string]: string };
  }) => {
    let url = `${routesMap[routeKey].baseRoute}/${appointmentId}?date=${appointmentDateISOString}`;

    if (addtlQueryParams) {
      Object.entries(addtlQueryParams).forEach(([key, val]) => {
        url = url + `&${key}=${val}`;
      });
    }

    navigate(url);
  };

  return { navigateToBaseRouteWithAptmtDetails };
};
