// React
import React, { useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// react-query components
import { QueryClientProvider, QueryClient } from 'react-query';

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React routes
import routes, { routesMap } from "data/routes";
import { RouteKeys, type Route as RouteType } from "./models/RouteModels";
import { useAuth } from "hooks/useAuth";
import Loader from "components/atoms/Loader/Loader";
import { unauthorizedInvite } from "services/checkUnauthorizedInviteService";
import { User } from "models/UserModels";

import ScheduleCallPage from "components/pages/ScheduleCallPage/ScheduleCallPage";
import colors from "assets/theme/base/colors";
import useFetchFeatureFlags from "hooks/useFetchFeatureFlags";
import { FeatureFlagsProvider } from "contexts/FeatureFlagContext";
import { ProductVersion } from "models/OrgModels";
import { AppSettingsProvider } from "contexts/AppSettingsContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function App() {
  const { user, loadingAuthState } = useAuth();
  const { featureFlags, featureFlagsLoading } = useFetchFeatureFlags();
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getAuthenticatedRoutes = (allRoutes: RouteType[], _user: User | null): React.ReactNode[] => allRoutes
    .filter((route) => {
      if (route.permissionSlug && !_user?.can(route.permissionSlug)) return false;
      if (route.unauthedUnonboardedOnly) return false;
      return true;
    })
    .map((route) => {
      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  const authenticatedRoutes = useMemo(() => getAuthenticatedRoutes(routes, user), [user]);
  const onboardingComplete = user && user.orgs[0]?.is_org_subscribed;
  const onboardingIncomplete = user && !unauthorizedInvite && !user.orgs[0]?.is_org_subscribed;
  const notAuthenticated = !onboardingComplete && !onboardingIncomplete;
  const defaultRoute = user && user.orgs[0].product_version === ProductVersion.TRIAGE ? routesMap[RouteKeys.TRIAGE_LIST].route : routesMap[RouteKeys.PATIENT_LIST].route

  if (loadingAuthState || featureFlagsLoading) return <Loader />;

  return (
    <QueryClientProvider client={queryClient}>
      <FeatureFlagsProvider featureFlagValues={featureFlags}>
        <AppSettingsProvider appSettings={{
          productVersion: user!.orgs[0].product_version,
          homeRoute: defaultRoute
        }}>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            {!!process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT !== 'prod' ? <div style={{
              width: '100%',
              backgroundColor: colors.success.main,
              color: '#fff',
              textAlign: 'center'
            }}><h3> {process.env.REACT_APP_ENVIRONMENT.toUpperCase()} ENVIRONMENT </h3></div> : null}

            <Routes>
              {onboardingComplete && (
                <>
                  <Route path="schedule-call" element={<ScheduleCallPage />} />
                  {authenticatedRoutes}

                  <Route path="*" element={<Navigate to={defaultRoute} />} />
                </>
              )}
              {onboardingIncomplete && (
                <>
                  <Route path={routesMap[RouteKeys.ONBOARDING].route} element={routesMap[RouteKeys.ONBOARDING].component} />;
                  <Route path="*" element={<Navigate to={routesMap[RouteKeys.ONBOARDING].route} />} />
                </>
              )}
              {notAuthenticated && (
                <>
                  <Route path={routesMap[RouteKeys.SIGN_IN].route} element={routesMap[RouteKeys.SIGN_IN].component} />
                  <Route path={routesMap[RouteKeys.SIGN_UP].route} element={routesMap[RouteKeys.SIGN_UP].component} />
                  <Route
                    path="*"
                    element={(
                      <Navigate to={
                        unauthorizedInvite
                          ? `${routesMap[RouteKeys.SIGN_IN].route}?unauthorized=true`
                          : routesMap[RouteKeys.SIGN_IN].route}
                      />
                    )}
                  />
                </>
              )}
            </Routes>
          </ThemeProvider>
        </AppSettingsProvider>
      </FeatureFlagsProvider>
    </QueryClientProvider >
  );
}
