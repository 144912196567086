import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/MenuRounded';
import Container from '@mui/material/Container';
import MDAvatar from "components/atoms/MDAvatar/MDAvatar";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import colors from 'assets/theme/base/colors';
import { ReactComponent as Logo } from 'assets/images/logo/JointAILogo2023.svg';
import { useAuth } from 'hooks/useAuth';
import MDTypography from "components/atoms/MDTypography/MDTypography";
import { routesMap } from 'data/routes';
import { MUIColors, Sizes } from 'models/StyleModels';
import { RouteKeys } from 'models/RouteModels';
import { useQueryClient } from 'react-query';
import MDButton from 'components/atoms/MDButton/MDButton';
import PatientIntakeModal from '../PatientIntakeModal/PatientIntakeModal';
import MDBox from 'components/atoms/MDBox/MDBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import ActionSlugs, { IfUserIsAllowed } from 'services/PermissionsService';
import "App.css"
import { useUserData } from 'hooks/useUserData';
import { useAppSettings } from 'contexts/AppSettingsContext';
import { ProductVersion } from 'models/OrgModels';

// Display Proper Heading Based on Local Time
const currentDate = new Date();
const curHr = currentDate.getHours();
let headerGreeting = "";

if (curHr < 12) {
	headerGreeting = "Good Morning!";
} else if (curHr < 18) {
	headerGreeting = "Good Afternoon!";
} else {
	headerGreeting = "Good Evening!";
}

interface IResponsiveAppBarProps {
	hideQuickStartButtons?: boolean;
}

const ResponsiveAppBar: React.FC<IResponsiveAppBarProps> = ({ hideQuickStartButtons = false }) => {
	const { signOut, user } = useAuth();
	const queryClient = useQueryClient();
	const { userById } = useUserData({ preventRefetch: true });

	const { homeRoute, productVersion } = useAppSettings();
	const triageFlow = productVersion === ProductVersion.TRIAGE;

	const {
		CREATE$APPOINTMENT,
		VIEW$PATIENT_LIST_PAGE,
		VIEW$ORG_DETAILS_PAGE,
		VIEW$PROFILE_PAGE
	} = ActionSlugs;

	const handleSignOut = () => {
		queryClient.clear();
		signOut();
	};

	const [anchorElNav, setAnchorElNav] = useState<EventTarget & Element | null>(null);
	const [anchorElUser, setAnchorElUser] = useState<EventTarget & Element | null>(null);

	const handleOpenNavMenu = (event: React.MouseEvent) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: React.MouseEvent) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const [quickstartModalOpen, setQuickstartModalOpen] = useState<boolean>(false);

	const toggleQuickstartModal = () => setQuickstartModalOpen(!quickstartModalOpen);
	const closeModal = () => setQuickstartModalOpen(false);

	// TODO: this rendering logic could use some refactoring and streamlining
	return (
		<>
			{quickstartModalOpen && (
				<PatientIntakeModal
					mode='quickstart'
					closeModal={closeModal}
				/>
			)}
			<AppBar position="static" sx={{ pt: 3, pb: 3 }}>
				<Container maxWidth="sm">
					<Toolbar disableGutters>
						<Typography
							variant="h5"
							noWrap
							sx={{
								mr: 2,
								display: { xs: 'none', md: 'flex' },
							}}>
							<div className='header-greeting-wrapper'>
								<Link to={homeRoute}>
									<Logo style={{ height: '60px', width: 'auto' }} />
								</Link>
								<MDBox
									className='header-greeting-text'
									sx={{ display: { lg: 'block', md: 'none' } }}>
									<div className='header-greeting-main'> {headerGreeting} </div>
									<div className='header-greeting-secondary' style={{ color: colors.text.focus }}> Welcome to JointDx </div>
								</MDBox>
							</div>
						</Typography>
						<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit">
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: 'block', md: 'none' },
								}}>

								<IfUserIsAllowed to={VIEW$PATIENT_LIST_PAGE}>
									<Link to={homeRoute}>
										<MenuItem key="Dashboard" onClick={handleCloseNavMenu}>
											<Typography textAlign="right">Home</Typography>
										</MenuItem>
									</Link>
								</IfUserIsAllowed>


								{!triageFlow && <IfUserIsAllowed to={CREATE$APPOINTMENT}>
									<MenuItem
										key="Quickstart Exam"
										onClick={() => {
											toggleQuickstartModal();
											handleCloseNavMenu();
										}}>
										<Typography textAlign="right">Quickstart Exam</Typography>
									</MenuItem>
								</IfUserIsAllowed>
								}

							</Menu>
						</Box>
						<Typography
							variant="h5"
							noWrap
							sx={{
								mr: 2,
								display: { xs: 'flex', md: 'none' },
								flexGrow: 1,
								fontFamily: 'monospace',
								fontWeight: 700,
								letterSpacing: '.3rem',
								color: 'inherit',
								textDecoration: 'none',
							}}
						>
							<Link to={homeRoute}>
								<Logo style={{ height: '60px', width: 'auto' }} />
							</Link>
						</Typography>
						<Box sx={{ justifyContent: "flex-end", flexGrow: 1, pr: 3, display: { xs: 'none', md: 'flex' } }}>
							{!triageFlow && <IfUserIsAllowed to={CREATE$APPOINTMENT}>
								<MDButton
									onClick={toggleQuickstartModal}
									style={{ marginRight: '1rem' }}
									variant='outlined'
									color={MUIColors.SECONDARY}>
									<span style={{ fontWeight: 600, cursor: 'pointer' }}>Quickstart Exam</span>
								</MDButton>
							</IfUserIsAllowed>}
							<IfUserIsAllowed to={VIEW$PATIENT_LIST_PAGE}>
								<Link to={homeRoute}>
									<Button
										variant='contained'
										key="Dashboard"
										color="secondary"
										sx={{ color: 'white !important', display: 'block' }}>
										<FontAwesomeIcon icon={faHouse} />
									</Button>
								</Link>
							</IfUserIsAllowed>
						</Box>

						<Box sx={{ flexGrow: 0 }}>
							<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
								<MDAvatar src={userById?.user.avatar_url ?? ''} size={Sizes.XX_LARGE} alt="Avatar" />
							</IconButton>

							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}>
								<MenuItem key='Email' disabled color='dull'>
									<MDTypography textAlign='center'>
										{user?.details.email}
									</MDTypography>
								</MenuItem>
								<IfUserIsAllowed to={VIEW$PROFILE_PAGE}>
									<Link to={routesMap[RouteKeys.PROFILE].route}>
										<MenuItem key="Profile" onClick={handleCloseUserMenu}>
											<Typography textAlign="center">Profile</Typography>
										</MenuItem>
									</Link>
								</IfUserIsAllowed>
								<IfUserIsAllowed to={VIEW$ORG_DETAILS_PAGE}>
									<Link to={routesMap[RouteKeys.ORG_DETAILS].route}>
										<MenuItem key="Organization" onClick={handleCloseUserMenu}>
											<Typography textAlign="center">Organization</Typography>
										</MenuItem>
									</Link>
								</IfUserIsAllowed>
								<MenuItem key="Logout" onClick={handleSignOut}>
									<Typography textAlign="center">Logout</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Toolbar>
				</Container>
			</AppBar >
		</>
	);
};
export default ResponsiveAppBar;
