import { useMutation, useQueryClient } from 'react-query';
import HttpService from 'services/httpService';
import { ENDPOINT_BASE_URL } from './constants';
import { useAuth } from './useAuth';
import { Appointment, AppointmentRankedJoints, NewAppointment, ReasonsForVisit } from 'models/AppointmentModels';
import { AppointmentQueryCache, AppointmentsQueryCache, appointmentQueryKeys } from './useAppointmentData';
import { SurveyTypes } from 'models/SurveyModels';

// see this section of the notion documentation for more information on how react query is utilized in this project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff

export const useAppointmentMutations = () => {
  const { user, accessToken } = useAuth();
  const queryClient = useQueryClient();

  const postAppointment = async (newAppointment: NewAppointment): Promise<{ data: { appointment: Appointment } }> => {
    const resp = await HttpService.post({
      url: `${ENDPOINT_BASE_URL}/appointments/?org_id=${user?.orgs[0].org_id}`,
      data: newAppointment,
      token: accessToken,
    });
    return resp as unknown as { data: { appointment: Appointment } };
  };

  interface UpdateAppointmentRequest {
    appointmentId: string;
    appointmentDate: string;
    newValues: {
      joints?: AppointmentRankedJoints;
      survey_type?: SurveyTypes;
      reason_for_visit?: ReasonsForVisit;
      physician?: string;
    }
  }

  interface DeleteAppointmentRequest {
    appointmentId: string;
    appointmentDate: string;
  }

  const patchAppointment = async (requestData: UpdateAppointmentRequest): Promise<{ data: { appointment: Appointment } }> => {
    const resp = await HttpService.patch({
      url: `${ENDPOINT_BASE_URL}/appointments/${requestData.appointmentId}?org_id=${user?.orgs[0].org_id}&appointment_date=${requestData.appointmentDate}`,
      data: requestData.newValues,
      token: accessToken,
    });
    return resp as unknown as { data: { appointment: Appointment } };
  };

  const {
    mutateAsync: createAppointment,
    data: createAppointmentData,
    isLoading: createAppointmentLoading,
    isSuccess: createAppointmentSuccessful,
    isError: createAppointmentError,
  } = useMutation(
    (newAppointment: NewAppointment) => postAppointment(newAppointment),
    {
      onSuccess: (resp) => {
        const newAppointment = resp.data.appointment;
        const prevAppointments: AppointmentsQueryCache = queryClient
          .getQueryData<AppointmentsQueryCache>(appointmentQueryKeys.getAllAppointmentsKey(newAppointment.appointment_date));

        if (prevAppointments) {
          queryClient.setQueryData<AppointmentsQueryCache>(appointmentQueryKeys.getAllAppointmentsKey(newAppointment.appointment_date), {
            ...prevAppointments,
            data: {
              ...prevAppointments.data,
              appointments: [...prevAppointments.data.appointments, newAppointment],
            },
          });
        }
      },
    },
  );

  const {
    mutateAsync: updateAppointment,
    isLoading: updateAppointmentLoading,
    isSuccess: updateAppointmentSuccessful,
    isError: updateAppointmentError,
  } = useMutation(
    (requestData: UpdateAppointmentRequest) => patchAppointment(requestData),
    {
      onSuccess: (resp) => {
        const updatedAppointment = resp.data?.appointment;
        if (!updatedAppointment) return;
        const prevAppointment: AppointmentQueryCache = queryClient
          .getQueryData<AppointmentQueryCache>(appointmentQueryKeys.getAppointmentKey(updatedAppointment.appointment_date, updatedAppointment.appointment_id));

        if (prevAppointment) {
          queryClient.setQueryData<AppointmentQueryCache>(appointmentQueryKeys.getAppointmentKey(updatedAppointment.appointment_date, updatedAppointment.appointment_id), {
            ...prevAppointment,
            data: {
              ...prevAppointment.data,
              appointment: updatedAppointment,
            },
          });
        }
      },
    },
  );

  const deleteAppointment = async (appointmentId: string, appointmentDate: string): Promise<{ data: { appointment: Appointment } }> => {
    const resp = await HttpService.destroy({
      url: `${ENDPOINT_BASE_URL}/appointments/${appointmentId}?org_id=${user?.orgs[0].org_id}&appointment_date=${appointmentDate}`,
      token: accessToken,
    });
    return resp as unknown as { data: { appointment: Appointment } };
  };

  const {
    mutateAsync: deleteAppointmentMutation,
    isLoading: deleteAppointmentLoading,
    isSuccess: deleteAppointmentSuccessful,
    isError: deleteAppointmentError,
  } = useMutation(
    (requestData: DeleteAppointmentRequest) => deleteAppointment(requestData.appointmentId, requestData.appointmentDate),
    {
      onSuccess: (resp) => {
        const deletedAppointment = resp.data.appointment;
        const prevAppointments: AppointmentsQueryCache = queryClient
          .getQueryData<AppointmentsQueryCache>(appointmentQueryKeys.getAllAppointmentsKey(deletedAppointment.appointment_date));

        if (prevAppointments) {
          queryClient.setQueryData<AppointmentsQueryCache>(appointmentQueryKeys.getAllAppointmentsKey(deletedAppointment.appointment_date), {
            ...prevAppointments,
            data: {
              ...prevAppointments.data,
              appointments: prevAppointments.data.appointments.filter((appointment) => appointment.appointment_id !== deletedAppointment.appointment_id),
            },
          });
        }
      },
    },
  );

  return {
    createAppointment,
    newAppointment: createAppointmentData?.data.appointment,
    createAppointmentLoading,
    createAppointmentSuccessful,
    createAppointmentError,

    updateAppointment,
    updateAppointmentLoading,
    updateAppointmentSuccessful,
    updateAppointmentError,

    deleteAppointmentMutation,
    deleteAppointmentLoading,
    deleteAppointmentSuccessful,
    deleteAppointmentError,
  };
};
